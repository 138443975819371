import { useState, useEffect, useRef, useCallback } from "react";

const defaultState = {
    duration:0,
    progress:0,
    currentTime: 0,
    canPlay: false
};

export default function useAudio ({ src, autoPlay, onSongComplete }){
    const [state, setState] = useState(defaultState);
    const audioRef = useRef(null);
    
    const reset = () => setState(defaultState);

    const transferAudioState = useCallback(() => {
        const currentTime = Math.floor(audioRef.current.currentTime);
        const duration = Math.floor(audioRef.current.duration);
        const progress = duration === 0
            ? 0
            : Math.floor((currentTime / duration) * 100);

        setState({
            ...state,
            currentTime,
            duration,
            progress
        });
    }, [audioRef, state]);

    const handleToggle = useCallback(() => {
        if (audioRef.current.paused) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
        transferAudioState();
    }, [audioRef]);

    const handleSeek = useCallback(newProgress => {
        audioRef.current.currentTime = newProgress * audioRef.current.duration * 0.01;
        transferAudioState();
    }, [audioRef]);

    useEffect(() => {
        const el = document.createElement("AUDIO");
        document.body.appendChild(el);
        el.setAttribute("src", src);
        el.style.display = "none";
        audioRef.current = el;
        return () => document.body.removeChild(el)
    },  []);
    
    useEffect(() => {
        if(!audioRef.current) {
            return;
        }
        let handleCanPlay = () => setState({ ...state, canPlay: true});
        audioRef.current.addEventListener("canplay", handleCanPlay);
        return () =>  audioRef.current.removeEventListener("canplay", handleCanPlay)
    }, [audioRef.current]);
    
    useEffect(() => {
        if(typeof(onSongComplete) !== "function") {
            return;
        }
        audioRef.current.addEventListener("ended", onSongComplete);
        return () => audioRef.current.removeEventListener("ended", onSongComplete);
    }, [onSongComplete]);
    
    useEffect(() => {
        const emitInterval = window.setInterval(transferAudioState, 500);
        return () => window.clearInterval(emitInterval)
    });
    
    useEffect(() => {
        if(!audioRef.current) {
            return;
        }
        reset();
        autoPlay && audioRef.current.play();
    }, [src, audioRef.current]);
    return {
        ...state,
        handleToggle: handleToggle,
        handleSeek: handleSeek,
    };
}