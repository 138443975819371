import React from "react";
import useAudio from "../../hooks/useAudio"
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core';

export default function ActiveSong({song, onSongComplete}) {
    const src = `content/songs/${song.year}/${song.name}.mp3`;
    const {progress, currentTime, canPlay} = useAudio({ src, autoPlay: true, onSongComplete});

    return (
        <React.Fragment>
            <div className="p-2">
                <StopButton>&#x25a0;</StopButton> {song.name}
            </div>
            <div style={{background: "#CCC", margin: "4px 8px 0 8px"}}>
                <ProgressBar progress={progress}/>
            </div>
            <div style={{padding: "4px 8px 8px 8px", textAlign: "center"}}>
                {canPlay ? formatTime(currentTime) : "loading..."}
            </div>
        </React.Fragment>
    );
}

const spinAnimation = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -10px, 0);
  }

  70% {
    transform: translate3d(0, -7px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

const StopButton = styled.div`
    animation: ${spinAnimation} 1s ease infinite;
    display:inline-block;
`;

const ProgressBar = styled.div`
    width: ${props => props.progress === 0 ? 1 : props.progress}%; 
    background:rgb(199, 4, 4); 
    height:10px;
`;

function formatTime(str) {
    const sec_num = parseInt(str, 10);
    const hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return minutes+':'+seconds;
}
