import React from "react";
import Song from './Song/Index.js';
import styled from '@emotion/styled'

export default function Year({year, songs, selectedSong, ...rest}){
    return (
        <Container
            className="w-full lg:w-2/5 mt-5 md:mx-5 p-1">
            <h3 className="p-3 text-center">{year}</h3>
            {songs
                .map(song =>
                <Song key={song.name}
                      song={song}
                      isSelected={selectedSong && song.name === selectedSong.name}
                      {...rest}
                />
            )}
        </Container>
    );
}

const Container = styled.div`
    background: rgb(52, 135, 51)
`;