import styled from '@emotion/styled'
import React from "react";
import ActiveSong from "./ActiveSong";
import InactiveSong from "./InactiveSong";

export default function Song({song, onChangeSong, isSelected, onSongComplete}){
    return (
        <Container
            isSelected
            className="my-2 text-sm"
            onClick={() => onChangeSong(song)}>
            {isSelected 
                ? <ActiveSong song={song} onSongComplete={onSongComplete} />  
                : <InactiveSong song={song} /> }
        </Container> 
    );
};

const Container = styled.div`
    background: rgba(199, 4, 4, 0.2);
    color:${props => props.isSelected ? "#FFF" : "#c4dac4"};
    cursor:pointer;
    white-space:nowrap;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: max-height .3s ease-in-out;
    max-height:${props => props.isSelected ? "80px" : "40px"};
    &:hover {
        background: ${props => !props.isSelected && "rgba(199, 4, 4, 0.4)"};
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`;