// eslint-disable-next-line
import React, {Component, useState, useCallback} from 'react';
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import {css, Global, jsx, keyframes } from '@emotion/core';
import styled from '@emotion/styled'
import { music } from '../data.js'
import Year from './Year.js'
import SnowStorm from 'react-snowstorm'

export default function App(){
    const [selectedSong, setSelectedSong] = useState(null);
    
    const changeSong = newSelectedSong => {
        const isSameSong = selectedSong 
                                && selectedSong.name === newSelectedSong.name;
        setSelectedSong(
            isSameSong
                ? null
                : newSelectedSong
        );
    };
    
    const handleSongComplete = useCallback (() => {
        if(!selectedSong){
            return;
        }
        const arrMusic = Object.keys(music).map(x => music[x]);
        const flattenedMusic = [].concat(...arrMusic);
        const currentIndex = flattenedMusic.findIndex(el => el.name === selectedSong.name);
        const newIndex = currentIndex + 1 === arrMusic.length 
                            ? 0 
                            : currentIndex + 1;
        setSelectedSong(flattenedMusic[newIndex]);
    }, [selectedSong]);
    
    return (
          <div className="container mx-auto pt-8">
              
              <Global styles={globalStyles} />
              
              {selectedSong && selectedSong.name === "Let it Snow Now" 
                && <SnowStorm excludeMobile={false} animationInterval={50}/>})
                
              <StyledTopLights className="fixed h-12 pin-x pin-t bg-repeat-x"  />
              
              <div className="flex flex-wrap">
                  
                  <StyledPhoto src="content/images/photo.jpg" 
                               alt="Josh and Dave"  
                               className="mx-auto flex-no-grow flex-1"/>
                  
                  <div className="flex-grow flex-1 md:ml-8 mt-12 md:mt-0 text-white md:text-black">

                      <div className="flex flex-wrap justify-center">
                          {Object
                              .keys(music)
                              .map(year =>
                                  <Year
                                      key={year}
                                      year={year}
                                      songs={music[year]}
                                      onChangeSong={changeSong}
                                      selectedSong={selectedSong}
                                      onSongComplete={handleSongComplete}
                                  />
                              )}
                      </div>
                      
                  </div>
                  
              </div>
              
          </div>
    );
};

const globalStyles = css`
    html {
        height:100%;
    }
    body {
        background: #2F7336;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to left, #AA3A38, #2F7336);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to left, #AA3A38, #2F7336); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        margin:0;
        min-height:100%;
        padding:40px 0 0 0;
    }
`;

const StyledPhoto = styled.img`
    width:349px;
    height:579px;
    transition:all 0.3s ease;
    &:hover, 
    &:active {
        transform:rotateZ(-3deg);
    }
`;

const topLightsAnimation = keyframes`
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
`;

const StyledTopLights = styled.div`
    background: url('./content/images/top_lights.png');
    animation: ${topLightsAnimation} 120s linear infinite;
`;
