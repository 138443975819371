const music = {
    "2011": [
        "Fa Lease Nah Veedaud",
        "Have a Loud Nagila",
        "Rockjolly Christmas",
        "Hark the Harold",
    ],
    "2010": [
        "Joy to the Metal World",
        "Let it Snow Now",
        "Rudolph the Red Nosed Rockstar"
    ],
    "2009": [
        "Little Drummer Boy",
        "Santa Lane",
        "Iron Shalom",
        "White Christmas",
        "Dreidel Opening"
    ],
    "2008": [
        "Drunken Halls of the Holidays",
        "Iron Menorah",
        "Mom and Santa are out of control!",
        "Pounding Bells",
        "Guitalujah"
    ]
};

Object
    .keys(music)
    .forEach(year =>
       music[year] = music[year].map(name => ({ name, year })) 
    );

export {
    music
};